import * as tslib_1 from "tslib";
import { Vue, Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import { accountRules } from '@/common/js/form-rule/index';
import { URLS } from '@/common/js/constant/index';
let DialogResetPassword = class DialogResetPassword extends Vue {
    constructor() {
        super(...arguments);
        this.form = {
            login_type: 'email',
            username: '',
            password: '',
            confirm_password: '',
        };
        this.formRules = {};
        this.isLoading = false;
    }
    onShowChanged(val) {
        if (!val) {
            const form = this.$refs.form;
            form.resetFields();
            this.form.login_type = 'email';
        }
    }
    created() {
        this.bindRules();
    }
    bindRules() {
        this.formRules = accountRules.call(null, this.form);
    }
    /**
     * 登录类型发生改变
     * @param {String} val 当前类型
     */
    onLoginTypeChanged(val) {
        const form = this.$refs.form;
        this.form.login_type = val;
        form.resetFields();
    }
    save() {
        const form = this.$refs.form;
        form.validate((valid) => {
            if (valid) {
                this.isLoading = true;
                this.$ajax.post(URLS.createUser, {
                    login_type: this.form.login_type,
                    username: this.form.username,
                    password: KLOOK.md5(this.form.password),
                }).then((res) => {
                    this.$successTips();
                    this.isShowDialog = false;
                    this.$emit('updated');
                }).finally(() => {
                    this.isLoading = false;
                });
            }
        });
    }
};
tslib_1.__decorate([
    Prop({ type: Object, default: () => ({}) })
], DialogResetPassword.prototype, "info", void 0);
tslib_1.__decorate([
    PropSync('show', { type: Boolean })
], DialogResetPassword.prototype, "isShowDialog", void 0);
tslib_1.__decorate([
    Watch('show')
], DialogResetPassword.prototype, "onShowChanged", null);
DialogResetPassword = tslib_1.__decorate([
    Component
], DialogResetPassword);
export default DialogResetPassword;
